import { Box, Card, CardActionArea, CardContent, CardHeader, CardMedia, Grid, Typography } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import TransliterationTable from "../components/transliterationTable"
import PageTemplate2 from "../templates/page2"
import Anchor from "../utils/anchor"

const MaterialCards = ({ material }) => (
  <Grid container style={{ padding: `8px 0` }}>
    {material.map(({ title, desc, image, url }) => (
      <Grid key={title} item xs={12} md={4}>
        <Card style={{ height: "100%" }}>
          <CardActionArea disabled={!url} href={url}>
            {image && (
              <CardMedia style={{ position: "relative" }}>
                <GatsbyImage
                  image={image.childImageSharp.gatsbyImageData}
                  alt={title}
                  style={{ height: "300px" }}
                  objectPosition={"top"}
                />
                <CardHeader
                  style={{
                    position: "absolute",
                    bottom: 0,
                    color: "#FFF",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    paddingTop: 8,
                    paddingBottom: 8,
                  }}
                  title={title}
                />
              </CardMedia>
            )}
          </CardActionArea>
          {desc && (
            <CardContent>
              <Typography variant="h4">{title}</Typography>
              <Typography>{desc}</Typography>
            </CardContent>
          )}
        </Card>
      </Grid>
    ))}
  </Grid>
)

const BookCards = ({ material }) => (
  <Grid container>
    {material.map(({ title, image }) => (
      <Grid key={title} item xs={12} md={3}>
        <Card style={{ height: "100%" }}>
          <CardContent>
            <GatsbyImage image={getImage(image)} alt={title} />
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
)

const ResourceCards = ({resourceItems}) => (
  <Grid container>
    {resourceItems.map(({ label, url, desc }) => (
      <Grid item md={4} key={label} style={{ width: "100%" }}>
        <Card style={{ height: "100%" }}>
          <CardActionArea component="a" href={url} target="_blank">
            <CardContent>
              <Typography variant="h4" color="primary">
                {label}
              </Typography>
              <Typography>{desc}</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    ))}
  </Grid>
)

const MaterialPage = ({location}) => {
  const {
    materialQuery: {
      childMarkdownRemark: {
        frontmatter: {
          title,
          coverImage,
          imageCrop,
          printMaterial,
          videoMaterial,
          digitizedMaterial,
          audioMaterial,
          sarrcItems,
          resourceItems,
          teachingTools
        },
        html,
      },
    },
    transliterationQuery,
  } = useStaticQuery(
    graphql`
      {
        materialQuery: file(name: { eq: "explore" }) {
          childMarkdownRemark {
            frontmatter {
              title
              coverImage {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                  )
                }
              }
              imageCrop
              printMaterial {
                desc
                title
              }
              videoMaterial {
                title
                desc
              }
              digitizedMaterial {
                title
                desc
              }
              audioMaterial {
                title
                desc
              }
              sarrcItems {
                title
                url
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              teachingTools {
                title
                url
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              resourceItems {
                label
                url
                desc
              }
            }
            html
          }
        }
        transliterationQuery: file(name: { eq: "transliteration" }) {
          childMarkdownRemark {
            html
          }
        }
      }
    `
  )

  return (
    <PageTemplate2
      title={title}
      location={location}
      coverImage={coverImage}
      imageCrop={imageCrop}
    >
      <Anchor name="material" />
      <div dangerouslySetInnerHTML={{ __html: html }} />
      <section>
        <h3>PRINT</h3>
        <MaterialCards material={printMaterial} />

        <h3>AUDIO</h3>
        <MaterialCards material={audioMaterial} />

        <h3>VIDEO</h3>
        <MaterialCards material={videoMaterial} />
      </section>
      <section>
        <h2>Digitized material</h2>
        <MaterialCards material={digitizedMaterial} />
      </section>
      <section>
        <Anchor name="sarrcpubs" />

        <h2>SARRC Publications</h2>
        <BookCards material={sarrcItems} />
      </section>
      <section>
        <h2>Teaching Revolutionary Papers at SARRC</h2>
        <p>
          In collaboration with{" "}
          <a href="http://revolutionarypapers.org/">Revolutionary Papers</a>,
          SARRC is developing Teaching Tools that allow educators and organisers
          to teach radical journals in classrooms and movement contexts. If you
          want to develop a Teaching Tool using a journal, magazine, pamphlet or
          other print from the SARRC collection, please get in touch with
          Revolutionary Papers on{" "}
          <a href="https://docs.google.com/forms/d/e/1FAIpQLScW9L4npVJdNoAFb_pnwizko2BqwvGaWWqBqFwaC6LqIE1QXw/viewform?usp=send_form">
            this link
          </a>
          .
        </p>
        <MaterialCards material={teachingTools} />
      </section>
      <section>
        <Anchor name="transliteration" />
        <Box
          pb={1}
          dangerouslySetInnerHTML={{
            __html: transliterationQuery.childMarkdownRemark.html,
          }}
        />
        <TransliterationTable />
      </section>
      <section>
        <Anchor name="resources" />

        <h2>Further resources</h2>
        <ResourceCards resourceItems={resourceItems} />
      </section>
    </PageTemplate2>
  )
}

export default MaterialPage